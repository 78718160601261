.submenu {
    width: 30%;
    background-color: #005EB8;
    padding: 20px 20px 20px 100px;
    overflow-y: auto;
    z-index: 500;
}
.submenu  .menu-head {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 10px 0;
}
.submenu .intro-menu .menu-indent {
    margin-top: 10px;
    margin-left: 20px;
}
.submenu .intro-menu {
    color: #002554;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0px;
}
.submenu .intro-menu li {
    counter-increment: table-ol;
    line-height: 1.6;
    background-image: none;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}
.submenu .intro-menu li {
    color: rgba(255, 255, 255, .6);
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 200ms ease;
    -moz-transition: color 200ms ease;
    -ms-transition: color 200ms ease;
    -o-transition: color 200ms ease;
    transition: color 200ms ease;
}
.submenu .intro-menu li:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}
.submenu .intro-menu li::before {
    font-size: 11px;
    color: rgba(255, 255, 255, .6);
        content: counter(table-ol, decimal-leading-zero) "";
    position: absolute;
    left: 0;
    margin-right: 5px;
    margin-top: 4px;
}

.submenu .ruler {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-top: 1px solid rgba(255, 255, 255, .6);
    padding-top: 15px;
}
.submenu .intro-menu .active {
    color: #FFFFFF;
    /* pointer-events: none; */
}
.submenu .intro-menu .submenu-active {
    font-family: "Avenirnextltpro Bold";
    color: rgba(255, 255, 255, 1);
    pointer-events: none;
    /* text-decoration: underline; */
}
.submenu .show {
    display: block;
}
.submenu .hide {
    top: -1000px;
    /* display: none; */
}

@media only screen and (max-width: 1100px) {

    .submenu {
        width: 40%;
    }
    .welcome-content-wrap .right {
        width: 100%;
    }
    .welcome-content-wrap .right {
        padding: 80px 40px 40px 40px;
    }
    .welcome-content-wrap .show-submenu {
        left: 0;
    }

}


@media only screen and (max-width: 820px) {

    .welcome-content-wrap .submenu-left {
        width: 100%;
        max-width: 100%;
        padding: 80px 20px 20px 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1000px;
        -webkit-transition: left .2s ease-out;
        -moz-transition: left .2s ease-out;
        -o-transition: left .2s ease-out;
        transition: left .2s ease-out;
    }
    .welcome-content-wrap .right {
        width: 100%;
    }
    .welcome-content-wrap .right {
        padding: 80px 40px 40px 40px;
    }
    .welcome-content-wrap .show-submenu {
        left: 0;
    }

}