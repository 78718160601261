@font-face {
  font-family: "Avenirnextltpro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Regular"), url("avenirnextltpro-regular.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Bold"), url("avenirnextltpro-bold.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Italic"), url("avenirnextltpro-it.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Demi";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Demi"), url("avenirnextltpro-demi.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Medium"), url("avenirnextltpro-medium.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Light"), url("avenirnextltpro-light.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Light Italic"), url("avenirnextltpro-lightit.woff") format("woff");
}
@font-face {
  font-family: "Avenirnextltpro Light Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Avenirnextltpro Light Thin"), url("avenirnextltpro-thin.woff") format("woff");
}

@import url("https://use.typekit.net/ohg7obh.css");



h1 {
  font-family: "kaneda-gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 68px;
  line-height: 1;
  text-align: left;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 15px;
}

h2 {
  font-family: "Avenirnextltpro Bold";
  padding-bottom: 25px;
  padding-bottom: 25px;
  padding-top: 20px;
  line-height: 1.4;
  text-align: left;
  color: #FFFFFF;
}

h3 {
  font-family: "Avenirnextltpro Bold";
  font-size: 20px;
  padding-bottom: 25px;
  padding-top: 20px;
  line-height: 1.4;
  text-align: left;
  color: #002554;
  text-transform: uppercase;
}

p {
  font-family: "Avenirnextltpro Regular";
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 1em;
}
p:last-child {
  margin-bottom: 0;
}
.footnote {
  font-size: 14px;
  margin: 10px 0 20px;
}
.footnote-right {
  text-align: right;
}

strong {
  font-family: "Myriad Pro Bold";
}

/*UNORDERED LIST*/
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  font-family: "Avenirnextltpro Regular";
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
  margin: 0px 0px 20px 0px;
}
ul li {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D2B4E;%7D%0A%3C/style%3E%3Crect y='4.5' class='st0' width='10' height='1'/%3E%3C/svg%3E");  background-size: 5px;
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: 0px 6px; 
  padding-left: 20px;
  margin-bottom: 5px;
}

/*ORDERED LIST*/
ol {
  display: table;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  color: #ffffff;
  margin-bottom: 10px;
  font-family: "Avenirnextltpro Regular";
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  /*border-spacing: 5px;*/
}
ol li {
	margin-left: 30px;
    display: table-row;
    counter-increment: table-ol;
	
}
ol li p {
  margin-bottom: 0px;
}
ol li:before {
	content: counter(table-ol) ".";
	display: table-cell;
	padding-right: 0.8em;
	text-align: right;
	/* font-weight: bold; */
}
