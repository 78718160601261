.plate-button {
    display: block;
    width: 100%;
    max-width: 800px;
    position: relative;
    padding: 25px 20px 20px 20px;
    background-color: #efefef;
    /* height: 100px; */
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);
    cursor: pointer;
}
.pb-abs {
    width: 35px;
    height: 35px;
    position: absolute;
    background-repeat: no-repeat;
    -webkit-background-size: 35px;
    background-size: 35px;
    pointer-events: none;
}
.pb-hover {
    position: absolute;
    top: 11px;
    left: 12px;
    bottom: 10px;
    right: 12px;
    background-color: rgba(0,0,0,0.1);
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease;
    pointer-events: none;
}
.plate-button:hover > .pb-hover {
    opacity: 1;
}
.pb-imgs {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
}
.pb-cornerTL {
    background-image: url("../../assets/images/btnTL.png");
    top: 0;
    left: 0;
    z-index: 1;
}
.pb-cornerTR {
    background-image: url("../../assets/images/btnTR.png");
    top: 0;
    right: 0;
    z-index: 1;
}
.pb-cornerBL {
    background-image: url("../../assets/images/btnBL.png");
    bottom: 0;
    left: 0;
    z-index: 1;
}
.pb-cornerBR {
    background-image: url("../../assets/images/btnBR.png");
    bottom: 0;
    right: 0;
    z-index: 1;
}
.pb-lineTop {
    width: 100%;
    background-image: url("../../assets/images/btnTline.png");
    background-repeat: repeat-x;
    top: 0;
    left: 0;
    right: 0;
}
.pb-lineBottom {
    width: 100%;
    background-image: url("../../assets/images/btnBline.png");
    background-repeat: repeat-x;
    bottom: 0;
    left: 0;
    right: 0;
}
.pb-lineLeft {
    height: 100%;
    background-image: url("../../assets/images/btnLline.png");
    background-repeat: repeat-y;
    top: 0;
    left: 0;
}
.pb-lineright {
    height: 100%;
    background-image: url("../../assets/images/btnRline.png");
    background-repeat: repeat-y;
    top: 0;
    right: 0;
}
.pb-label {
    z-index: 10;
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pb-label p {
    font-size: 20px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
    text-align: center;
    color: #75787B !important;
    /* z-index: 10 !important; */
}
.btn-icon {
    width: 30px;
    height: 30px;
    background-color: #75787B;
    pointer-events: none;
    margin: -5px 10px 0 0;
}