
.selection-section-wrap {
    width: 100%;
    height: 100%;
    min-height: 768px;
    position: relative;
    /* top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/welcome-bg-noarch.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.selection-content {
    width: 100%;
    max-width: 1100px;
    margin: 40px 60px 60px 120px;
    display: flex;
    align-items: center;
}
.selection-col {
    display: flex;
    flex-direction: column;
}
.selection-col img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);
    margin-bottom: 30px;
}
.selection-col .pb-label img {
    width: 30px;
    box-shadow: none;
    margin-bottom: 0;
    
}
.selection-column-container {
    display: flex;
    justify-content: space-between;
}
/* .selection-text {
    position: absolute;
    top: 30px;
    bottom: 50px;
    left: 30px;
    right: 30px;
    overflow-y: scroll;
}
.selection-content {
    width: 100%;
    max-width: 1220px;
    margin: 40px 20px 60px 80px;
    display: flex;
    justify-content: space-between;
}
.selection-content h1 {
    color: #002554;
}
.selection-content p, .selection-content ul {
    color: #003A7A;
}
.border-text {
    padding: 30px;
    border: 2px solid #002554;
}
.selection-left-col {
    display: flex;
    justify-content: center;
    align-items:flex-start; 
}
.selection-left-col img {
    display: block;
    width: 100%;
    height: auto;
}
.small-rule {
    width: 80px;
    height: 3px;
    background-color: #002554;
    margin: 30px 0 20px 0;
}
.selection-enter-wrap {
    width: 270px;
    height: 95px;
    position: absolute;
    left: 50%;
    bottom: -55px;
    margin-left: -135px;
    background-image: url("../../assets/images/home-enter-btn@2x.png");
    background-repeat: no-repeat;
    background-size: 270px 95px;
    cursor: pointer;

}
.hover-state {
    position: absolute;
    top: 10px;
    left: 12px;
    bottom: 19px;
    right: 22px;
    opacity: 0;
    background-color: rgba(255, 255, 255, .6);
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease;
    pointer-events: none;
    z-index: 1;
}
.home-enter-wrap:hover > .hover-state { opacity: 1; }

.home-enter-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}
.home-enter-btn p {
    font-size: 26px;
    line-height: 1;
    color: #75787B;
    text-transform: uppercase;
    margin-left: 40%;
} */


@media only screen and (max-width: 820px) {

    .root {
        /* height: auto */
    }
    .selection-column-container {
        display: flex;
        flex-direction: column;
    }
    .selection-content {
        width: 100%;
        max-width: 1220px;
        margin: 100px 30px 60px 30px;
        justify-content: center;
    }
    .selection-col {
        max-width: 300px;
        margin-bottom: 60px;
    }
    .selection-col img {
        margin-bottom: 10px;
    }
    

}

@media only screen and (max-width: 420px) {
    
    .home-left-col img {
        display: block;
        width: auto;
        height: 100%;
        max-height: 400px;
    }

}