.piContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.3);;
    display: none;
}
.pi {
    width: 600px;
    height: 400px;
    position: fixed;
    left: 70px;
    bottom: 50px;
    background-color: #FFFFFF;
    z-index: 1000;
    -webkit-box-shadow:0px 0px 5px 2px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,.3);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,.3);
    /* display: none; */
}
.pis-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.showref {
    display: block;
}
.ref-content {
    height: 333px;
    padding: 10px 20px 40px 20px;
    overflow-y: auto;
}
.pi h2 {
    font-size: 16px;
    color: #444444;
    padding: 20px 0 5px 20px;
}
.pi p {
    font-size: 12px;
    color: #444444;
    padding: 0;
    margin: 0;
}
.pi .mg-bt {
    margin-bottom: 10px;
}
.pi .mg-top {
    margin-top: 10px;
}
.pi sup {
    top: -.45em
}
.pi ol {
    font-size: 12px;
    color: #444444;
    margin-bottom: 0;
}

.pi .ae-border {
    border: 1px solid #000000;
    padding: 10px;
}
.pi .bold {
    font-weight: bold;
}
.pi a {
    color: #005EB8;
}
@media (max-width: 820px) {

    .pi {
        width: auto;
        max-width: initial;
        height: auto;
        position: fixed;
        left: 20px;
        right: 20px;
        bottom: 20px;
    }
    
}