#intro-content .intro-anim-content {
    max-width: 1220px;
}
#intro-content h2 {
    color: #002554;
    font-size: 26px;
    margin: 0 0 15px 0;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    /* border-bottom: 2px solid #005EB8; */
}
#intro-content h2::after {
    content: " ";
    display: block;
    background: #005EB8;
    height: 1px;
    width: 30px;
    margin-top: 10px;
  }
#intro-content .explore-number {
    color: #002554;
    font-size: 26px;
    margin: 0 0 15px 0;
    line-height: 1;
    display: inline-block;
    border-bottom: 1px solid #005EB8;
}
#intro-content h1 {
    font-family: "Avenirnextltpro Bold";
    font-size: 38px;
    color: #002554;
    margin: 0;
}
#intro-content .fw-text {
    width: 100%;
    max-width: 1024px;
    margin: 20px 0;
}
#intro-content .fw-img {
    width: 100%;
    min-height: 200px;
    max-width: 1024px;
    margin: 20px 0;
    background-color: #ededed;
}
#intro-content .width100 {
    width: 100%;
    margin: 20px 0 40px;
}
#intro-content .text50-img50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px;
}
#intro-content .img50-text50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px;
}
#intro-content .text50-text50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 40px;
}
#intro-content .width70 {
    width: 68%;
}
#intro-content .width50 {
    width: 48%;
}
#intro-content .width30 {
    width: 28%;
}
#intro-content .widthSwitchImg {
    width: 100%;
}
#intro-content .width280px {
    width: 280px;
    margin-left: 20px;
}
#intro-content .img {
    width: 100%;
    min-height: 300px;
    position: relative;
}
.fragment-wrapper-2col {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.lt-blue-wrapper-2col h3, .full-width-heading h3 {
    padding: 0 0 20px;
}
.lt-blue-wrapper-2col {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #BDD6E6;
    padding: 30px;
    margin-bottom: 40px;
}
.lt-blue-wrapper-1col {
    width: 100%;
    background-color: #BDD6E6;
    padding: 30px;
    margin-bottom: 40px;
}
.full-width-heading {
    width: 100%;
}
.frag-inset {
    padding: 20px;
    background-color: #FFFFFF;
    -webkit-box-shadow: inset 3px 3px 6px 1px rgba(0,37,84,0.5); 
    box-shadow: inset 3px 3px 6px 1px rgba(0,37,84,0.5);
}
.rule-footnote {
    padding-top: 10px;
    border-top: 1px solid #005EB8;
}
.rule-footnote p {
    font-size: 12px;
}

.general-popup .popup-ref {
    margin-top: 100px;
    padding-top: 10px;
    border-top: 1px solid #005EB8;
}
.general-popup .popup-ref p {
    font-size: 12px;
    color: #002554;
}
.general-popup .popup-ref li {
    color: #002554;
    font-size: 12px;
}


/* Popup */

@media only screen and (max-width: 620px) {
    .lt-blue-wrapper-1col {
        padding: 20px;
    }
    .lt-blue-wrapper-2col {
        padding: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 820px) {
    
    #intro-content .text50-img50 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #intro-content .img50-text50 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #intro-content .text50-text50 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    #intro-content .width50 {
        width: 100%;
        margin-bottom: 20px;
    }
    #intro-content .width70 {
        width: 100%;
        margin-bottom: 20px;
    }
    #intro-content .width30 {
        width: 100%;
        margin-bottom: 20px;
    }
    #intro-content .img {
        margin-top: 20px;
    }
    .lt-blue-wrapper-2col {
        flex-direction: column;
    }

}

@media only screen and (max-width: 1440px) {
    
    .frag-inset, {
        padding: 20px;
        flex-direction: column;
    }
    #intro-content .width280px {
        width: 100%;
        min-width: 280px;
        margin-left: 0;
    }

}

@media only screen and (max-width: 800px) { 
    .fragment-wrapper-2col {
        flex-direction: column;
    }
}