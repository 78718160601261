.explore-content {
    /* padding-bottom: 20px; */
    max-height: 800px;
    /* max-height: calc(100vh - 120px);
    overflow-y: auto;
    padding: 20px 120px 20px 120px;
    margin-bottom: 10px;
    margin-top: 10px; */
}
.explore-content h2 {
    font-family: "kaneda-gothic", sans-serif;
    font-size: 90px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    color: #002554;
}
.explore-content h2 sup {
    font-size: 30%;
    top: -1.8em;
}
.explore-content p, .explore-content li {
    color: #002554;
}
.explore-number {
    color: #002554;
    font-size: 26px;
    margin: 0 0 10px 0;
    line-height: 1;
    display: inline-block;
    /* border-bottom: 2px solid #002554; */
}
.explore-number::after {
    content: " ";
    display: block;
    background: #005EB8;
    height: 2px;
    width: 30px;
    margin-top: 10px;
}
#explore-content .fw-text {
    width: 100%;
    max-width: 1024px;
    margin: 0 0 40px;
}
#explore-content .fw-img {
    width: 100%;
    min-height: 200px;
    max-width: 1024px;
    margin: 20px 0;
    background-color: #ededed;
}
#explore-content .width100 {
    width: 100%;
    margin: 20px 0 40px;
}
#explore-content .text50-img50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0 40px;
}
#explore-content .img50-text50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0 40px;
}
#explore-content .text50-text50 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0 40px;
}
#explore-content .width70 {
    width: 68%;
}
#explore-content .width50 {
    width: 48%;
}
#explore-content .width30 {
    width: 28%;
}
#explore-content .widthSwitchImg {
    width: 100%;
}
#explore-content .width280px {
    width: 280px;
    margin-left: 20px;
}
#explore-content .img {
    width: 100%;
    min-height: 150px;
    position: relative;
    /* background-color: #005EB8; */
    margin: 0 0 0 0;
}
#explore-content img {
    width: 100%;
}
#explore-content .lt-blue-wrapper {
    background-color: #BDD6E6;
    padding: 20px;

}
.fragment-wrapper-2col {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.no-btm-mg {
    margin-bottom: 0 !important;
}

.tab-content {
    padding-bottom: 20px;
}

.tab-content-active {
    display: block;
}

.underline-txt {
    text-decoration: underline;
}

@media (max-width: 820px) {

    .explore-content {
        padding-bottom: 20px;
    }

    #explore-content .text50-img50 {
        flex-direction: column;
    }
    #explore-content .img50-text50 {
        flex-direction: column;
    }
    #explore-content .text50-text50 {
        flex-direction: column;
    }
    #explore-content .width70 {
        width: 100%;
    }
    #explore-content .width50 {
        width: 100%;
    }
    #explore-content .width30 {
        width: 100%;
    }

    #explore-content .img {
        margin: 0 0 20px 0;
    }
    
}

@media (max-width: 680px) {
    /* #explore-content .img {
        margin: 0;
    } */
    #explore-content .text50-img50 {
        margin-bottom: 20px;
    }

    #explore-content .text50-img50 .width50{
        margin-bottom: 20px;
    }

    .explore-text-img {
        display: block;
    }
    .explore-text {
        width: 100%;
        height: auto;
    }
    .explore-img {
        width: 100%;
        margin-top: 10px;
    }
    
  }

@media (max-width: 420px) {

     h2 {
        font-size: 60px;
    }
    
  }