.slider-wrap {
    width: 260px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 0;
    margin-bottom: 20px;
    cursor: pointer;
}
.slider-label {
    width: 100%;
    max-width: 160px;
}
.slider-label p {
    margin: 0;
    padding: 0;
}
.slider-track {
    width: 40px;
    height: 20px;
    margin-right: 20px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #002554;
    background-color: #BDD6E6;
    cursor: pointer;
}
.slider-slider-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 20px;
    border: 1px solid #002554;
    background-color: #FFFFFF;
    -webkit-transition: left .3s ease;
    -moz-transition: left .3s ease;
    -o-transition: left .3s ease;
    -ms-transition: left .3s ease;
    transition: left .3s ease;
}
.slb-active {
    left: 19px;
    border: 1px solid #002554;
    background-color: #005EB8;
}