.download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    position: relative;
    padding: 16px 10px 10px 10px;
    background-color: #ffffff;
    border: 1px solid #005EB8;
    /* height: 100px; */
    -webkit-box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3); 
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    margin-top: 20px;
    margin-bottom: 40px;
}
.download-button:hover {
    background-color: #BDD6E6;
}
.download-button .pb-label {
    z-index: 10;
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.download-button .pb-label p {
    font-family: "Avenirnextltpro Bold";
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #002554 !important;
}
.download-button .download-icon {
    width: 15px;
    height: 20px;
    position: relative;
    margin-right: 5px;
}
.download-button .download-icon svg {
   position: absolute;
   top: 0px;
   left: 0px;
}
.download-button .di0 {
    fill:none;
    stroke:#005EB8;
    stroke-width:4;
}
.download-button .di1{
    fill:#005EB8;
}