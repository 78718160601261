#full-body-anatomy .full-body-wrap {
    width: 100%;
    position: relative;
    background-color: #FFFFFF;
}
#full-body-anatomy h2 {
    margin: 0;
}
#full-body-anatomy h3 {
    font-size: 16px;
    padding: 0;
}
#full-body-anatomy .intro-layer-skeleton {
    width: 100%;
    min-height: 300px;
    position: relative;
}
#full-body-anatomy .img-full-body {
    width: 100%;
    position: absolute;
    min-height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: .6; */
}
#full-body-anatomy .intro-img-overlay {
    position: absolute;
    top: 0;
    left: 0;

    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;


}
#full-body-anatomy .full-body-intro-img {
    width: 100%;
}
#full-body-anatomy .full-body-intro-img img {
    width: 100%;
}
#full-body-anatomy .intro-muscle-layer {
    z-index: 1;
}
#full-body-anatomy .intro-fat-layer {
    z-index: 2;
}

#full-body-anatomy .layer-hidden {
    /* display: none; */
    opacity: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}

#full-body-anatomy .full-body-img-wrapper {
    width: 100%;
    padding: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    -webkit-box-shadow: inset 1px 1px 5px 2px rgba(0,37,84,0.69); 
    box-shadow: inset 1px 1px 5px 2px rgba(0,37,84,0.69);
}
#full-body-anatomy .full-body-images {
    position: relative;
    width: auto;
    display: block;
    flex: 1 1 auto;
    margin-right: 10px;
}
#full-body-anatomy .full-body-switches {
    width: 180px;
}



@media only screen and (max-width: 600px) { 
    #full-body-anatomy .full-body-img-wrapper {
        flex-direction: column-reverse;
    }

}