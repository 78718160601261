.section-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../../assets/images/lightConcreteLt.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .welcome-content-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: rgba(0, 0, 0, .2); */
  }
  
  /* .welcome-content-wrap .left {
    width: 30%;
    background-color: #005EB8;
    padding: 20px 20px 20px 100px;
    overflow-y: scroll;
    z-index: 500;
  } */
  .welcome-content-wrap .right {
    position: relative;
    width: 70%;
    /* background-color: #FFFFFF; */
    padding: 40px 40px 40px 100px;
    overflow-y: auto;
    margin-bottom: 30px;
  }
  /* #welcome  .menu-head {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0;
    padding: 10px 0 10px 0;
  } */
  #welcome p {
    color: #002554;
  }
  /* #welcome .intro-menu .menu-indent {
    margin-top: 10px;
    margin-left: 20px;
  }
  #welcome .intro-menu {
    color: #002554;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0px;
  }
  #welcome .intro-menu li {
    counter-increment: table-ol;
    line-height: 1.6;
    background-image: none;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
  }
  #welcome .intro-menu li {
    color: rgba(255, 255, 255, .6);
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 200ms ease;
    -moz-transition: color 200ms ease;
    -ms-transition: color 200ms ease;
    -o-transition: color 200ms ease;
    transition: color 200ms ease;
  }
  #welcome .intro-menu li:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  #welcome .intro-menu li::before {
    font-size: 11px;
    color: rgba(255, 255, 255, .6);
      content: counter(table-ol, decimal-leading-zero) "";
    position: absolute;
    left: 0;
    margin-right: 5px;
    margin-top: 4px;
  } */

  .into-img {
    width: 100%;
  }
  .into-img img {
    width: 100%;
  }
  
  .intro-content {
    color: #002554;
  }
  /* #welcome ul li a:hover #welcome ul li:before {
    color: rgba(255, 255, 255, 1);
    font-size: 111px;
  } */
  .ruler {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-top: 1px solid rgba(255, 255, 255, .6);
    padding-top: 15px;
  }
  #welcome .intro-menu .active {
    color: #FFFFFF;
    /* pointer-events: none; */
  }
  .show {
    display: block;
  }
  .hide {
    top: -1000px;
    /* display: none; */
  }
  
  .welcome-content-wrap .full-width {
    width: 100%;
  }
  
  
  
  
  @media only screen and (max-width: 1100px) {
  
    .welcome-content-wrap .left {
      width: 40%;
    }
    .welcome-content-wrap .right {
      width: 60%;
      padding: 40px 40px 40px 40px;
    }
  
  }
  
  
  @media only screen and (max-width: 820px) {
    /* .welcome-content-wrap .left {
      width: 100%;
      max-width: 100%;
      padding: 80px 20px 20px 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1000px;
      -webkit-transition: left .2s ease-out;
      -moz-transition: left .2s ease-out;
      -o-transition: left .2s ease-out;
      transition: left .2s ease-out;
    } */
    .welcome-content-wrap .right {
      width: 100%;
    }
    .welcome-content-wrap .right {
      padding: 80px 40px 40px 40px;
    }
    /* .welcome-content-wrap .show-submenu {
      left: 0;
    } */
  }
  
  @media only screen and (max-width: 620px) {
    .welcome-content-wrap .right {
      padding: 80px 20px 40px 20px;
    }
  }
  
  
  