.home-section-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #D7EFF5;
    background-image: url("../../assets/images/welcome-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 0 right 60%;
}
.home-right-col {
    position: relative;
    margin-bottom: 80px;
    background-color: rgba(255, 255, 255, .6);
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 100px);
}
.home-text {
    padding: 30px;
    /* position: absolute;
    top: 30px;
    bottom: 50px;
    left: 30px;
    right: 30px; */
    overflow-y: auto;
}
.home-content {
    width: 100%;
    max-width: 1220px;
    margin: 55px 20px 60px 80px;
    /* display: flex;
    justify-content: space-between; */
}
.home-content h1 {
    color: #002554;
}
.home-content p, .home-content ul {
    color: #003A7A;
}
.border-text {
    padding: 30px;
    border: 2px solid #002554;
}
.home-left-col {
    display: flex;
    justify-content: center;
    align-items:flex-start; 
}
.home-left-col img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}
.small-rule {
    width: 80px;
    height: 3px;
    background-color: #002554;
    margin: 30px 0 20px 0;
}
.home-enter-wrap {
    width: 270px;
    height: 95px;
    margin-bottom: 20px;
    margin-top: 20px;
    /* position: absolute;
    left: 50%;
    bottom: -55px;
    margin-left: -135px; */
}
.hover-state {
    position: absolute;
    top: 10px;
    left: 12px;
    bottom: 19px;
    right: 22px;
    opacity: 0;
    background-color: rgba(255, 255, 255, .6);
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease;
    pointer-events: none;
    z-index: 1;
}
.home-enter-wrap:hover > .hover-state { opacity: 1; }

/* Home text list */
.home-text .welcome-list {
    margin: 20px 0;
}
.home-text .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.home-text .icon {
    width: 44px;
    height: 44px;
    margin-right: 20px;
    background-color: #005EB8;
    background-repeat: no-repeat;
    background-size: 44px;
    background-position: center;
}
.home-text .anatomy {
    background-image: url("../../assets/images/anatomy-icon.svg");
}
.home-text .gallery {
    background-image: url("../../assets/images/gallery-icon.svg");
}
.home-text .welcome-list-text {
    width: 100%;
}

.home-enter-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
}
.home-enter-btn p {
    font-size: 26px;
    line-height: 1;
    color: #75787B;
    text-transform: uppercase;
    margin-left: 40%;
}


@media only screen and (max-width: 1024px) {
    .home-section-wrap {
        background-position: top 0 right 70%;
    }
}


@media only screen and (max-width: 820px) {

    .home-content h1 {
        font-size: 48px;
    }
    
    .home-section-wrap {
        position: absolute;
        background-image: url("../../assets/images/welcome-bg-mob.jpg");
        background-position: top center;
    }
    .home-content {
        flex-direction: column;
        margin: 80px 20px 80px 20px;
    }
    .home-left-col img {
        display: block;
        width: auto;
        height: 100%;
        max-height: 600px;
    }
    .home-text {
        position: relative;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
    }
    .border-text {
        padding: 20px 20px 60px 20px;
    }
    .home-right-col {
        max-height: calc(100vh - 140px);
    }
    .home-left-col {
        max-height: 420px
    }

}

@media only screen and (max-width: 420px) {
    
    .home-left-col img {
        display: block;
        width: auto;
        height: 100%;
        max-height: 400px;
    }

}