#app { height: 100% }

html,
body {
  position: relative;
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

img {
  display: block;
}