.musc-ill-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.musc-ill {
    /* width: 100%; */
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
}
.musc-circle {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    padding: 30px;
    border: 2px solid #005EB8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
}
.musc-circle p {
    font-family: "Avenirnextltpro Regular";
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    color: #002554;
}