.mySwiper {
    width: 100%;
    height: 100%;
}
.parallax-bg {
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/background-1.png");
    /* background-repeat: repeat-x     ; */
    -webkit-background-size: cover;
    background-size: cover;
    /* background-position: top center; */
    background-position: top 0 center;
    background-position: right calc(100% - 45.5%) top 0;
    /* -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; */
}

.swiper-horizontal>.swiper-scrollbar {
    position: fixed;
    left: 0;
    right: 0;
    /* bottom: 30px; */
    bottom: 50px;
    z-index: 50;
    height: 10px;
    width: 100%;
    border-radius: 0;
    background: rgba(255,255,255,.6);
}


.swiper-scrollbar-drag {
    background-color: #005EB8;
    position: relative;
    border-radius: 0;
  }
  
  .swiper-scrollbar-drag:before {
    content: '';
    background-color: #005EB8;
    position: relative;
    left: -99vw;
    top: 0;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100vw;
    z-index: 999;
  }


.swiper-pagination-bullet .lbl {
    width: 100%;
    position: absolute;
    /* top: -17px; */
    top: -29px;
    font-family: "Avenirnextltpro Bold";
    font-size: 11px;
    color: #FFF;
    text-transform: uppercase;
}

.scaffold {
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 50%;
    background-image: url("../../assets/images/scaffold.png");
    background-repeat: repeat-x     ;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
}
.light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/lights.png");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: top center;
    opacity: 0;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.light-on {
    opacity: .6;
}

.swiper-slide {
    height: 100%;
    min-height: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    /* -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; */
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-pagination {
    width: calc( 100% - 58px ) !important;
    left: 58px !important;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    /* position: fixed; */
    position: absolute;
    /* bottom: 50px !important; */
    bottom: 80px !important;
}

.swiper-pagination-bullet {
    width: 80px;
    height: 80px;
    border-radius: 0;
    position: relative;
    /* background-image: url("../../assets/images/thumb0.png"); */
    background-repeat: no-repeat;
    -webkit-background-size: 68px;
    background-size: 80%;
    background-position: center;
    opacity: .6;
    background-color: transparent;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.swiper-pagination-bullet:nth-child(1) {
    background-image: url("../../assets/images/thumb0.png");
}
.swiper-pagination-bullet:nth-child(2) {
    background-image: url("../../assets/images/thumb1.png");
}
.swiper-pagination-bullet:nth-child(3) {
    background-image: url("../../assets/images/thumb2.png");
}
.swiper-pagination-bullet:nth-child(4) {
    background-image: url("../../assets/images/thumb3.png");
}
.swiper-pagination-bullet:nth-child(5) {
    background-image: url("../../assets/images/thumb4.png");
}
.swiper-pagination-bullet:nth-child(6) {
    background-image: url("../../assets/images/thumb5.png");
}
.swiper-pagination-bullet-active {
    border: 2px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 1;
}

.swiper-button-prev {
    color: #FFF;
    left: 70px;
}
.swiper-button-next {
    color: #FFF;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 30px;
}

#audio-player audio {
    width: 280px;
}
.audio-wrapper {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    justify-content: center;
    background-color: rgba(255,255,255,0);
    z-index: 100000;
    -webkit-transition: opacity .6s ease;
    -moz-transition: opacity .6s ease;
    -o-transition: opacity .6s ease;
    -ms-transition: opacity .6s ease;
    transition: opacity .6s ease;
    pointer-events: none;
}
.display-audio {
    opacity: 1;
    display: flex;
}
.audio-player {
    width: 100%;
    max-width: 600px;
    /* display: flex; */
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    background-color: rgba(0, 0, 0, 1);
    pointer-events: all;
}
/* Audio Biog */
.audio-biog-wrap {
    margin: 20px 0 0 0;
}
.biog-btn {
    padding: 7px 15px;
    border: 1px solid #FFFFFF;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-transition: background-color .3s ease;
    -moz-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    -ms-transition: background-color .3s ease;
    transition: background-color .3s ease;
}
.biog-btn:hover {
    background-color: rgba(255, 255, 255, .4);
}
.biog-btn .lbl p {
    font-family: "Avenirnextltpro Bold";
    margin: 0;
}
.biog-btn .lbl p span {
    font-family: "Avenirnextltpro Regular";
    margin: 0;
}
.audio-player .content-img { 
    width: 30%;
    background-color: #000000;
    float: left;
    margin: 0 20px 10px 0;
}
.audio-player .content-img img { 
    width: 100%;
    display: block;
}
.chev {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' style='enable-background:new 0 0 30 30;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='21.9,15 10.2,26.7 8.1,24.5 17.7,15 8.1,5.5 10.2,3.3 '/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
}
.chev-rot{
    transform: rotate(90deg);
    transform-origin: center;
}
.biog-content {
    
    display: none;
    margin-top: 15px;
    /* height: 0;
    overflow: hidden;
    -webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    -ms-transition: all 3s ease;
    transition: all 3s ease; */
}
.biog-active {
    display: block;
    /* height: auto;
    display: block;
    -webkit-transition: height 3s ease;
    -moz-transition: height 3s ease;
    -o-transition: height 3s ease;
    -ms-transition: height 3s ease;
    transition: height 3s ease; */
}
.global-close {
    margin-left: auto;
}
.rhap_container, .rhap_time {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}


