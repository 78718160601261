.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Overlay--after-open{
  opacity: 1;
}
.ReactModal__Overlay--before-close{
  opacity: 0;
}
.close-btn {
  width: 40px;
  height: 40px;
  position: relative;
  /* top: 11px;
  right: 10px; */
  /* top: -34px;
  right: 0; */
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #005EB8;
  border: 1px solid #005EB8;
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  -ms-transition: background-color 200ms ease;
  -o-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.close-btn-transparent {
  width: 40px;
  height: 40px;
  position: relative;
  /* top: -34px;
  right: 0; */
  /* top: 20px;
  right: 20px; */
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #005EB8;
  border: 1px solid #005EB8;
  background-color: #FFFFFF;
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  -ms-transition: background-color 200ms ease;
  -o-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.close-btn:hover, .close-btn-transparent:hover {
  background-color: #b5bee1;
}
.close-btn svg, .close-btn-transparent svg {
  width: 12px !important;
}

.ReactModal__Content {
  /* width: 100%; */
  position: relative;
  max-width: 1024px;
  padding: 0 !important;
}
.scroll-class {
  /* height: 100%; */
  max-height: 1024px;
  /* overflow-y: scroll !important; */
  /* overflow-y: overlay !important;*/
  overflow: unset !important; 
  border-top-right-radius: 4px !important;

}
.ReactModal__Body--open {
  overflow: hidden;
}
.modal-header {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 800px;
  height: 72px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 10;
  border-bottom: 1px solid #005EB8;
}
.modal-header-transparent {
  position: sticky;
  top: 0;
  width: 100%;
  /* max-width: 800px; */
  height: 60px;
  z-index: 100;
}
.modal-content {
 /* height: calc(100vh - 120px); */
 max-height: 800px;
 margin-top: -60px;
}
#react-modal {
  max-height: calc(100vh - 120px);
  overflow-y: auto !important;
}
.modal-content-scroll-class {
  /* position: absolute;
  top: 20px;
  bottom: 20px; */
  /* max-height: 820px) {; */
  /* max-height: calc(100vh - 120px); */
  /* overflow-y: auto; */
  /* padding: 20px 120px 20px 120px; */
  padding: 20px 80px;
  margin-bottom: 10px;
  /* margin-top: 10px; */
}
.modal-content-scroll-statue-class {
  /* max-height: calc(100vh - 140px);
  overflow-y: auto;
  padding: 40px 40px 40px 40px; */
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding: 40px 40px;
  margin-bottom: 10px;
}

@media (max-width: 960px) {

  .modal-content-scroll-class {
    padding: 0px 20px 0px 20px;
  }
  .modal-content-scroll-statue-class {
    padding: 60px 20px 60px 20px;
  }
  .explore-content .width40, .explore-content .width60 {
    margin-bottom: 40px;
  }
  .explore-content {
    max-height: unset;
  }
  
}

@media (max-width: 460px) {

  .ext-link-confirm {
    padding: 20px 0;
  }
  .ext-link-buttons {
    display: block;
  }
  .ext-link-btn {
    margin: 20px 0;
  }
  .modal-content-scroll-statue-class {
    max-height: calc(100vh - 180px);
    padding: 20px 20px 20px 20px;
  }
  .modal-content-scroll-class {
    max-height: calc(100vh - 180px);
  }
  
}