.sculpture-wrap {
    width: 100%;
    height: 100%;
    max-width: 680px;
    max-height: 800px;
    min-height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(0,0,0,0.4); */
}
.sculpture {
    /* width: 680px;
    height: 800px; */
    width: 100%;
    height: 100%;



    /* position: absolute; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* cursor: pointer; */
    
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center; 
    margin: 0 auto;
}
.sculpture0 {
    background-image: url("../../assets/images/sculpturesAbdomen.png");
}
.sculpture1 {
    background-image: url("../../assets/images/sculpturesFlanks.png");
}
.sculpture2 {
    background-image: url("../../assets/images/sculpturesButtocks.png");
}
.sculpture3 {
    background-image: url("../../assets/images/sculpturesThighs.png");
}
.sculpture4 {
    background-image: url("../../assets/images/sculpturesArms.png");
}
.sculpture5 {
    background-image: url("../../assets/images/sculpturesSubmental.png");
}

.slide-content {
    width: 100%;
    max-width: 1200px;
    height: calc(100% - 140px);
    position: relative;
    padding: 40px 40px 40px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
}
.slide-content .title {
    width: auto;
    /* width: 100%; */
    height: 100%;
    /* max-width: 400px; */
    padding-top: 10%;
    /* position: absolute;
    top: 20%;
    bottom: 0;
    left: 80px; */
    z-index: 10;
}
.slide-content h1 {
    font-size: 140px;
    text-align: left;
    color: #FFF;
    text-transform: uppercase;
}
.button-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.button-group {
    width: auto;
    /* margin-bottom: 40px; */
}
.explore-sound-btns {
    display: flex;
    margin-bottom: 25px;
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));
}
.play-sound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
    padding: 5px 20px;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}
.play-sound:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.play-sound p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}
.view-btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.view-btn:last-child {
    margin-bottom: 0;
}
.view-btn .fat-icon {
    width: 38px;
    height: 38px;
    border: 1px solid #FFFFFF;
    margin-right: 10px;
    background-repeat: no-repeat;
    -webkit-background-size: 80%;
    background-size: 80%;
    background-position: center;
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));
    cursor: pointer;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}
.label {
    margin-top: 8px;
}

.active {
    background-image: url("../../assets/images/eye-open.svg");
}
.inactive {
    background-image: url("../../assets/images/eye-close.svg");
}

.fat-layer {
    width: 100%;
    height: 100%;
    opacity:0;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center;
    transition:opacity .2s ease-in;
    -webkit-transition: opacity .2s  ease-in;
    -moz-transition: opacity .2s  ease-in;
    -o-transition: opacity .2s  ease-in;
}
.sculpture0 .fat-layer {
    background-image: url("../../assets/images/sculpturesAbdomenFat.png");
}
.sculpture1 .fat-layer {
    background-image: url("../../assets/images/sculpturesFlanksFat.png");
}
.sculpture2 .fat-layer {
    background-image: url("../../assets/images/sculpturesButtocksFat.png");
}
.sculpture3 .fat-layer {
    background-image: url("../../assets/images/sculpturesThighsFat.png");
}
.sculpture4 .fat-layer {
    background-image: url("../../assets/images/sculpturesArmsFat.png");
}
.sculpture5 .fat-layer {
    background-image: url("../../assets/images/sculpturesSubmentalFat.png");
}

.show-fat {
    opacity:1;
    /* animation: fade-in 1s; */
}
.fat-layer-visible {
    /* display: block; */
    animation: fade-in .2s;
    animation-fill-mode: forwards;
}
.fat-layer-invisible {
    animation: fade-out .2s;
    animation-fill-mode: forwards;
}
.instruction {
    font-size: 12px;
    margin-bottom: 5px;
}




.view-btn > .fat-icon svg {
    -webkit-box-shadow:0px 2px 8px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
}
.view-btn .label p {
    font-family: "Avenirnextltpro Bold";
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    color: #FFFFFF;
    margin: 0;
}
.view-btn .label p span {
    font-family: "Avenirnextltpro Bold";
    /* text-transform: uppercase; */
    font-size: 12px;
}

.view-btn.btnInactive > .label p {
    opacity: 1;
    /* text-decoration: underline; */
}
.view-btn.btnActive > .label p {
    text-decoration: none;
}
.fat-icon:hover {
   background-color: rgba(255, 255, 255, 0.2);
}
/* .menu {
    width: 40%;
    max-width: 620px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -2000px;
    padding: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    border-left: 1px solid #FFF;
} */
.show {
    display: block;
}
.opac {
    opacity: .8;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}

/* .close-menu {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #FFF;
    border-left: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}
.close-menu:hover {
    background-color: rgba(255, 255, 255, 0.2);
} */

.pointer-off {
    pointer-events: none;
}
.disable-nav {
    pointer-events: none;
    opacity: .3;
}
.hotspot-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    /* opacity: 0; */
    -webkit-transition: all 1500ms ease;
    -moz-transition: all 1500ms ease;
    -ms-transition: all 1500ms ease;
    -o-transition: all 1500ms ease;
    transition: all 1500ms ease;
    /* pointer-events: none; */
}
.hotspot {
    width: 100%;
    height: 100%;
    position: absolute;
    /* cursor: pointer; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #FFF;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
/* .hotspot:hover {
    opacity: .6;
} */
/* .hotspot1 {
    top: 0;
    left: 0;
} */
/* .hotspot2 {
    top: 50%;
    right: calc(50% - 20%);
} */
.show {
    opacity: 1;
    pointer-events: auto;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}
.ReactModal__Overlay {
    z-index: 10000;
    background-color: rgba(0, 37, 84, 0.75) !important;
    
}

.explore-now {
    display: flex;
    align-items: center;
    font-family: "kaneda-gothic", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 48px;
    line-height: 1;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 1px solid #FFF;
    padding: 10px 20px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}
.explore-now:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.subTitle {
    /* position: absolute; */
    /* width: 100%; */
    /* bottom: 0; */
    /* bottom: 50px; */
    /* left: 100px; */
}

.secondary-head {
    /* position: absolute;
    bottom: 0; */
}

.secondary-head h2 {
    font-family: "kaneda-gothic", sans-serif;
    font-size: 36px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1;
}




.audio-statue-links-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
}
.audio-statue-links {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: rgba(0,0,0,0.4);
}
.audiolink {
    width: 20%;
    height: 30%;
    position: absolute;
    right: 8%;
    top: 13%;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

/* .statuelink0 {
    width: 20%;
    height: 36%;
    position: absolute;
    right: 8%;
    bottom: 16%;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}
.statuelink1{
    width: 20%;
    height: 36%;
    position: absolute;
    right: 29%;
    bottom: 16%;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
} */





@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.play-sound svg {
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.3));
  }

@media only screen and (min-width: 1440px) {

    .slide-content {
        max-width: 100%;
    }

    .slide-content .title {
        position: absolute;
        left: 140px;
        top: 100px;
        padding-top: 0;
        /* height: calc(100% - 140px); */
        /* height: calc(100% - 200px); */
        height: auto;
    }

    .sculpture-wrap {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 400px;
    }

    .parallax-bg {
        background-position: right calc(100% - 50.5%) top 0;
    }

}


@media only screen and (max-width: 960px) {
    .menu {
        width: 40%;
    }
    .sculpture-wrap {
        width: 100%;
        max-width: 800px;
        height: 100%;
        /* margin-bottom: 40px; */
    }
    .sculpture {
        width: 100%; 
        height: 100%;
    }
    .slide-content .title {
        width: 40%;
    }
}

@media only screen and (max-width: 820px) {
    .slide-content {
        padding: 40px 40px 40px 40px;
        flex-direction: column;
    }
    .slide-content .title {
        width: 100%;
        height: auto;
        padding-top: 0;
    }
    /* .secondary-head {
        position: relative;
    } */
    .subTitle {
        position: relative;
        left: 0;
    }
    .secondary-head h2 {
        margin: 0;
        padding: 0;
    }
    .swiper-button-prev {
        left: 10px;
    }
    .slide-content h1 {
        font-size: 100px;
        text-align: left;
        color: #FFF;
        text-transform: uppercase;
    }
    .explore-now {
        font-size: 38px;
        margin-right: 40px;
        margin-bottom: 0;
    }
    .button-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
    }
    .button-group {
        margin-bottom: 0;
    }
    .swiper-pagination {
        width: 100% !important;
        left: 0 !important;
        padding: 0 10%;
        display: flex;
        justify-content: space-between;
        /* bottom: 50px !important; */
        bottom: 70px !important;
    }
    .swiper-pagination-bullet {
        width: 50%;
        height: 0;
        padding-bottom: 14%;
    }

  }



  @media only screen and (max-width: 820px) {
    .sculpture-wrap {
        min-height: 300px;
    }
    
    .slide-content {
        /* padding: 80px 40px 100px 40px; */
        padding: 80px 40px 40px 40px;
        flex-direction: column;
    }
    .slide-content .title {
        width: 100%;
        height: auto;
        /* padding-top: 120px; */
    }
    .secondary-head {
        margin-top: 10px;
        position: relative;
    }
    .secondary-head h2 {
        margin: 0;
        padding: 0;
    }
    .swiper-button-prev {
        left: 10px;
    }
    .slide-content h1 {
        font-size: 80px;
        text-align: left;
        color: #FFF;
        text-transform: uppercase;
    }
    .explore-now {
        font-size: 34px;
        margin-right: 10px;
        margin-bottom: 0;
    }
    .play-sound {
        margin-right: 10px;
    }
    .view-btn .label p {
        font-size: 18px;
    }
    .secondary-head h2 {
        font-size: 26px;
    }
    .button-wrap {
        display: flex;
        flex-direction: row;
        /* align-items: flex-end; */
        /* margin-bottom: 20px; */
    }
    .explore-sound-btns {
        margin-bottom: 0;
    }
    /* .button-group {
        margin-bottom: -3px;
    } */
    .swiper-pagination {
        width: 100% !important;
        left: 0 !important;
        padding: 0 10%;
        /* padding: 0; */
        display: flex;
        justify-content: space-between;
        /* bottom: 100px !important; */
    }
    .swiper-pagination-bullet {
        width: 50%;
        height: 0;
        padding-bottom: 14%;
    }
    /* .swiper-horizontal>.swiper-scrollbar {
        bottom: 82px;
    } */

    .parallax-bg {
        background-position: right calc(100% - 50%) top 0;
    }

  }

    @media only screen and (max-height: 740px) {
        .sculpture-wrap {
            min-height: 200px;
        }
        .slide-content {
            height: calc(100% - 100px);
        }
        .sculpture-wrap {
            min-height: 220px;
        }
        .slide-content .title {
            padding-top: 0;
        }
    }

    @media only screen and (max-height: 620px) {
        .ReactModal__Overlay {
            padding: 0px !important;
        }
    }

  @media only screen and (max-width: 420px) {
    .swiper-pagination {
        padding: 0;
    }
    .swiper-pagination-bullet .lbl {
        font-size: 9px;
    }
    .explore-now {
        font-size: 28px;
        padding: 10px 10px;
    }
    .view-btn .label p {
        font-size: 16px;
    }
    .button-wrap {
        margin-bottom: 10px;
    }
    .sculpture-wrap {
        height: calc(100% - 200px);
    }
    .slide-content {
        height: calc(100% - 120px);
        display: block;
    }
    .slide-content h1 {
        font-size: 70px;
        margin-bottom: 5px;
    }
    .parallax-bg {
        /* background-position: right calc(100% - 50%) top -71px; */
    }
    .play-sound svg {
        width: 20px;
        height: 20px;
    }
    .play-sound p {
        font-size: 14px;
    }

  }






  @media only screen and (min-width: 1700px) {
    /* .sculpture-wrap {
        width: 460px;
        height: 460px;
        position: absolute;
        bottom: 13%;
    } */
  }
