.referenceContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.3);;
    display: none;
}
.reference {
    width: 600px;
    height: 400px;
    position: fixed;
    left: 70px;
    bottom: 50px;
    background-color: #FFFFFF;
    z-index: 1000;
    -webkit-box-shadow:0px 0px 5px 2px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,.3);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,.3);
    /* display: none; */
}
.references-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.showref {
    display: block;
}
.ref-content {
    height: 333px;
    padding: 10px 20px 40px 20px;
    overflow-y: auto;
}
.reference h2 {
    font-size: 16px;
    color: #444444;
    padding: 20px 0 5px 20px;
}
.reference ol {
    font-size: 14px;
    color: #444444;
}


@media (max-width: 820px) {

    .reference {
        width: auto;
        max-width: initial;
        height: auto;
        position: fixed;
        left: 20px;
        right: 20px;
        bottom: 20px;
    }
    
}