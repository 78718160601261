.connective-link-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.connectives {
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.conn-link {
    margin: 0 2% 60px 2%;
}
.conn-link img {
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5); 
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.5);
}

.connective-link-wrap .width30 {
    width: 26%;
}


@media only screen and (max-width: 1330px) {
    
    .connective-link-wrap .width30 {
        width: 40%;
    }

}

@media only screen and (max-width: 600px) {
    
    .connective-link-wrap .width30 {
        width: 100%;
    }

}