.atd-ill-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.atd-ill {
    /* width: 100%; */
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
}
.atd-circle {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    padding: 20px;
    border: 2px solid #005EB8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.atd-circle p {
    font-family: "Avenirnextltpro Bold";
    font-size: 20px;
    padding: 0;
    margin: 0;
    line-height: 1.2;
    text-align: center;
    color: #002554;
    text-transform: uppercase;
}