/* Job code at bottom of page */
.job-code-comp {
  width: 100%;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;
  display: flex;
}
.job-code-comp .job-code {
  width: calc(100% - 60px);
  padding-left: 20px;
}

.job-code p {
  font-size: 12px;
  color: #005eb8;
  margin: 0;
  text-align: right;
}

@media only screen and (max-width: 820px) {
    .job-code-comp {
      padding-right: 5px;
    }
  .job-code-comp .job-code {
    display: block;
    width: 100%;
    padding-left: 5px;
  }

  .job-code-comp .job-code p {
    font-size: 11px;
    line-height: 14px;
    text-align: left;
  }
}

@media only screen and (max-width: 520px) {

    .job-code-comp .job-code p {
      font-size: 11px;
      color: #005eb8;
      margin: 0;
    }
  }