.menu-wrap {
    width: 59px;
    /* height: 100%;  */
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #004990;
    border-right: 1px solid #FFF;
    z-index: 1000;
}
.with-footer {
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.2);
}
.menu-button {
    width: 58px;
    height: 58px;
    cursor: pointer;
    /* background-image: none; */
    background-repeat: no-repeat;
    background-size: 45px 45px;
    background-position: center;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}
.menu-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.bt {
    border-top: 1px solid #FFF;
}
.bb {
    border-bottom: 1px solid #FFF;
}
.bbt {
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}
.mg-btm {
    margin-bottom: auto;
}
.mrg-top {
    margin-top: auto;
}
.home-icon {
    background-image: url("../../assets/images/home-icon.svg");
}
.anatomy-icon {
    background-image: url("../../assets/images/anatomy-icon.svg");
}
.gallery-icon {
    background-image: url("../../assets/images/gallery-icon.svg");
}
.references-icon {
    background-image: url("../../assets/images/reference-icon.svg");
}
.ae-icon {
    background-image: url("../../assets/images/ae-icon.svg");
}
.logout-icon {
    background-image: url("../../assets/images/logout-icon.svg");
}
.menu-icon {
    display: none;
    background-image: url("../../assets/images/menu-icon.svg");
}
.logout-only {
    margin-top: auto;
}

.menu-wrap .inactive {
    display: none;
}

.menu-wrap .btn-active {
    background-color: #005EB8;
}
.btm0 {
    bottom: 0px;
}
.btm30 {
    /* bottom: 30px; */
    bottom: 30px;
    bottom: 50px;
}


@media only screen and (max-width: 820px) {

    .menu-wrap {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: unset;
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        border-right: none;
        border-bottom: 1px solid #FFFFFF;
        z-index: 1000;

        transition:left 1s linear;
    }
    .menu-button-inactive {
        cursor: auto;
        border-top: none;
        border-bottom: none;
        /* border-right: 1px solid #FFFFFF; */
    }
    .menu-button-inactive:hover{
        background-color: #004990;

    }
    .top-set, .middle-set, .bottom-set {
        display: flex;
    }
    .menu-button {
        width: calc(100%/6);
    }
    .menu-wrap .bb, .menu-wrap .bt, .menu-wrap .bbt {
        border-top: none;
        border-bottom: none;
        border-right: 1px solid #FFFFFF;
    }
    .bl {
        border-left: 1px solid #FFFFFF;

    }
    .menu-wrap .bt:last-child {
        border-right: none;
    }
    .menu-icon {
        display: block;
    }

}