.close-btn-container .close-btn {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid #005EB8;
    cursor: pointer;
    -webkit-transition: background-color 200ms ease;
    -moz-transition: background-color 200ms ease;
    -ms-transition: background-color 200ms ease;
    -o-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
}
.close-btn-container svg {
    color: #005EB8;
}

.close-btn-container .close-btn:hover {
    background-color: rgba(0, 94, 184, .3);
}