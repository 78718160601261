.fat9dist {
    border: 1px solid #005EB8;
    background-color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer
}
.fat9dist:first-child {
    margin-top: 0;
}
.fat-dist-head {
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.fat-dist-head p {
    font-size: 16px;
    font-family: "Avenirnextltpro Bold";
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    pointer-events: none;
}
.fdistexpand-content { 
    border-left: 1px solid #005EB8;
    border-right: 1px solid #005EB8;
    border-bottom: 1px solid #005EB8;
    max-height: 0;
    overflow: hidden;
    margin-top: -1px;
} 
.fdistexpand-text {
    margin: 20px;
}

.expand { 
    max-height: 1000px;
    transition: all 0.8s;
    -moz-transition: all 0.8s; 
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    opacity: 1;
}
.minimise { 
    max-height: 0px;
    transition: all 0.8s;
    -moz-transition: all 0.8s; 
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
}

#fat-dist .switch-image {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
#fat-dist .switch-active {
    display: block;
}
#fat-dist .fade-in-image {
    animation: fadeIn .5s;
    -webkit-animation: fadeIn .5s;
    -moz-animation: fadeIn .5s;
    -o-animation: fadeIn .5s;
    -ms-animation: fadeIn .5s;
}
.drop-indicator {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    right: 10px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' style='enable-background:new 0 0 30 30;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23285FA8;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='21.9,15 10.2,26.7 8.1,24.5 17.7,15 8.1,5.5 10.2,3.3 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    transition:all .3s ease-out;
    transform:rotate(0deg);
    -webkit-transform:rotate(0deg); /* Safari and Chrome */
    
}
.rot-arrow {
    transform:rotate(90deg);
    -webkit-transform:rotate(90deg); /* Safari and Chrome */
}



@media only screen and (max-width: 820px) { 
    /* .fat__dist-wrap .width50,
    .fat__dist-wrap .width50 .intro-img img {
    } */
    #intro-content .fat__dist-wrap .width50,
    #intro-content .fat__dist-wrap .img,
    .fat__dist-wrap .into-img img {
        height: 300px;
        padding-bottom: 20px;
    }


}

@media only screen and (max-width: 800px) { 
    .fat-dist-head {
        padding: 20px 10px;
    }
}