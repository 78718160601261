@import url("https://use.typekit.net/ohg7obh.css");


*:not(input) {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(113, 19, 113, 0.05);
  /* make transparent link selection, adjust opacity 0 to 1.0 */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}
*::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
*::-webkit-scrollbar-track {
  /* background-color:#DADDDD; */
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #025EB8;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
:focus {
  outline: none;
  outline-color: none;
  outline-style: none;
  outline-width: none;
}


html {
  background-color: #FFF;
}

body {
  background-color: #ccc !important;
  color: #1F3742;
  font-size: 36px;
    font-family: "Myriad Pro Bold";
}

#root {
  height: 100%;
}
.main {
  background-color: #ffffff;
  /* min-height: 820px {; */
  height: 100%;
}

a {
  color: #1F3742;
  text-decoration: underline;
}
a:hover {
  color: #8A9297;
  text-decoration: underline;
}

sub, sup {
	vertical-align: baseline;
	position: relative;
	font-size: 70%;
}
sub {
	bottom: -0.6em;
}
sup {
	top: -0.6em
}

.glow {
  -webkit-box-shadow:0px 2px 8px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.4);
}
.btn-glow {
  -webkit-box-shadow:2px 2px 8px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.4);
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.4);
}
.text-glow {
  text-shadow: rgba(0,0,0,0.4) 0px 4px 10px;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.section-wrap {
  min-height: 700px;
}
.emph {
  font-family: "Avenirnextltpro Bold";
}

.adaptive-break {
  display: none;
}

.width60 {
  width: 58%;
  /* background-color: rgba(255, 255, 255, .4); */
}
.width40 {
  width: 38%;
  /* background-color: rgba(255, 255, 255, .4); */
}
.width35 {
  width: 33%;
  /* background-color: rgba(255, 255, 255, .4); */
}
.width45 {
  width: 43%;
}
.width50 {
  width: 48%;
  /* background-color: rgba(255, 255, 255, .4); */
}

.mt0 {
  margin-top: 0 !important;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin: 0 0 20px 0 !important;
}
.mb40 {
  margin: 0 0 40px 0 !important;
}
.mb40 {
  margin: 0 0 60px 0 !important;
}

.btn {
  font-family: "Avenirnextltpro Bold";
  font-size: 16px;
  line-height: 1.4;
  color: #002554;
  text-transform: uppercase;
  border: 1px solid #005EB8;
  background-color: #FFFFFF;
  padding: 17px 15px 15px 15px;
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  -ms-transition: background-color 200ms ease;
  -o-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.btn:hover {
  background-color: #ccdff1;
}

.global-close {
  width: 34px;
  height: 34px;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23285FA8;%7D%0A%3C/style%3E%3Cpath id='_Color' class='st0' d='M32.5,6.4l-2.9-2.9L18,15.1L6.4,3.5L3.5,6.4L15.1,18L3.5,29.6l2.9,2.9L18,20.9l11.6,11.6l2.9-2.9 L20.9,18L32.5,6.4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  cursor: pointer;
  color: #005EB8;
  background-color: #FFFFFF;
  -webkit-transition: background-color 200ms ease;
  -moz-transition: background-color 200ms ease;
  -ms-transition: background-color 200ms ease;
  -o-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}
.global-close:hover {
  background-color: #ccdff1;
}


/* General popups */

.general-popup {
  padding: 30px;
}
.general-popup h1, .general-popup h2, .general-popup p, .general-popup ul {
  color: #002554;
}
.general-popup-dims {
  max-width: 1100px;
  max-height: calc(100vh - 100px);
  /* border-top-right-radius: 0 !important; */
}
.general-popup h2 {
  font-size: 20px;
  text-transform: uppercase;
  padding: 20px 0 10px;
}
.popup-2col {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.img {
  width: 100%;
  min-height: 150px;
  /* background-color: #005EB8; */
  margin-bottom: 20px;
}
.img-shadow {
  /* -webkit-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
  box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3); */
  /* box-shadow: 0px 5px 5px #888888; */
  /* filter: drop-shadow(1px 1px 3px grey); */
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=2, OffY=2, Color='#444')";
	filter: url(#drop-shadow);
	-webkit-filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3));
	filter: drop-shadow(2px 2px 5px rgba(0,0,0,0.3));
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }


  /* fade out */

  @keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
    }
    
    @-moz-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
    }
    
    @-webkit-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
    }
    
    @-o-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
    }
    
    @-ms-keyframes fadeOut {
    0% {opacity:1;}
    100% {opacity:0;}
    }

    @media only screen and (max-width: 960px) {

      .adaptive-break {
        display: inline;
      }

    }
    

     


@media only screen and (max-width: 820px) {

  #root {
    height: auto;
  }

  .width60 {
    width: 100%;
  }
  .width40 {
    width: 100%;
  }
  .width50 {
    width: 100%;
  }
  .width45 {
    width: 100%;
  }
  .width35 {
    width: 100%;
  }
  .popup-2col {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .section-wrap {
      min-height: 640px;
  }

}

@media only screen and (max-width: 620px) {
  .general-popup {
    padding: 20px 0px 20px 0px;
  }
}
@media only screen and (max-width: 420px) {
  .popup-2col .width50 {
    margin-bottom: 40px;
  }
}