.switch-image {
    /* position: absolute;
    top: 0;
    left: 0; */
    display: none;
}
.switch-active {
    display: block;
}
.fade-in-image {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}