.img-holder {
    width: 100%;
    background-color: #FFFFFF;
}
.img-holder img {
    max-height: calc(100vh - 100px);
    max-width: 820px;
    display: block;
}

.hotspot svg {
    enable-background:new 0 0 1280 1552;
    width: 100%;
    height: 100%;
}
.hotspot .zoom-btn {
    cursor: pointer;
}
.zoom-links .st0{fill:none;stroke:#FFFFFF;stroke-width:6;}
.zoom-links .st1{fill:none;stroke:#FFFFFF;stroke-width:6;stroke-linejoin:round;}
.zoom-links .st2{opacity:0;fill:#FFFFFF;cursor:pointer;}
.zoom-links .st3{opacity:0;fill:#FFFFFF;cursor:pointer;}
.zoom-links .st4{opacity:1;fill:#FFFFFF;}

#hs1:hover, #hs2:hover, #info1:hover, #info2:hover {
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease;
    opacity:.6
};

.info-link-1 {
    left: 30px;
}


.statue-desc {

}
.statue-desc .explore-content h2 {
    font-family: "kaneda-gothic", sans-serif;
    font-size: 90px;
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    color: #002554;
}
.statue-desc .explore-content p {
    color: #002554;
}
.statue-desc .explore-number {
    color: #002554;
    font-size: 26px;
    margin: 0 0 10px 0;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
}
.statue-desc {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.statue-desc img {
    width: 100%;
}
.statue-desc .width60 {
    width: 58%;
}
.statue-desc .width40 {
    width: 38%;
}

.fade-transition {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}

.audio-trans{fill:none;}
/* .audio-fill{fill:rgba(255, 255, 255, 0);cursor:pointer;} */
.audio-fill{fill:rgba(255, 255, 255, 0);}

.statue-hover {fill:rgba(0, 0, 0, 0);}
.audio-hover {fill:rgba(0, 0, 0, 0);}

#audiolink:hover > .audio-hover {fill:rgba(255, 255, 255, .4);}

#statuelink1:hover > .statue-hover {fill:rgba(255, 255, 255, .4);}
#statuelink0:hover > .statue-hover {fill:rgba(255, 255, 255, .4);}

/* statuelink0 */


@media (max-width: 780px) {


    .img-holder img {
        width: 100%;
        max-height: calc(100vh - 100px);
        max-width: calc(100vw - 30px);
        display: block;
    }
    .statue-desc {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .statue-desc .width60 {
        width: 100%;
    }
    .statue-desc .width40 {
        width: 100%;
    }
    
}

@media (max-width: 420px) {

    .statue-desc .explore-number {
        font-size: 20px;
    }
    
}